import React from 'react';
import ReactDOM from 'react-dom/client';
import { setGlobals } from 'common-ui';
import GlobalStyles from './styles/GlobalStyles';
import createTheme from './styles/theme';
import { CommonEmbeddedHeaderFooterApp, commonHeaderFooterAtgConfigAndEnvVars } from 'common-embedded';
import { CURRENCIES } from 'common-ui/src/utils/constants';

const initApp = () => {
  if (!window.newHeaderFooterInitData) {
    console.error('window data island "newHeaderFooterInitData" not found');
  }
  setGlobals({
    ...commonHeaderFooterAtgConfigAndEnvVars(window.newHeaderFooterInitData, window.newHeaderFooterBuildVersion),
    //general
    country: 'us',
    brandId: 'law',
    currencyCode: CURRENCIES.USD,
  });

  const theme = createTheme();
  const root = ReactDOM.createRoot(document.getElementById('newHeaderReactApp') as HTMLElement);
  root.render(
    <React.StrictMode>
      <CommonEmbeddedHeaderFooterApp theme={theme} GlobalStyles={GlobalStyles} />
    </React.StrictMode>,
  );
};
export default initApp;
